import { getSelectors } from '@ngrx/router-store';
import { createSelector, select } from '@ngrx/store';
import { ID } from 'src/app/core/definitions/types';
import { AutocadTreeNode } from 'src/app/core/interfaces/autocad-tree-node.interface';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
import { PatternDetail } from 'src/app/core/interfaces/pattern-detail.interface';
import { PatternSummary } from 'src/app/core/interfaces/pattern-summary.interface';
import { WorkroadsTreeNode } from 'src/app/core/interfaces/workroads-tree-node.interface';
import { ElementTypePipe } from 'src/app/shared/pipes/element-type.pipe';
import { Route } from '../../../core/constants/feature';
import { TARGET_SOFTWARE_ICONS } from '../../../core/constants/software-info';
import { DrawingTargetFramework } from '../../../core/definitions/enums';
import {
    findFieldInPattern,
    flattenAutocadTree,
    flattenValidationStateErrors,
    flattenWorkRoadsTree,
    treeExpressionErrors
} from '../../../core/helpers/pattern-utils';
import {
    Errors,
    ErrorType,
    PatternValidationError
} from '../../../core/interfaces/general-validation-result.interface';
import { fromRoot } from '../../../store';
import { HeaderItem } from '../../layout/interfaces/header-item';
import { InlineEditorId } from '../../shared-nodes/interfaces/field-editor-id';
import { patternsFeature } from './patterns.reducer';

export type PatternSummaryAndSelectedFramework = PatternSummary & {
    currentTargetFramework: { image: string; name: string };
};

const { selectQueryParams } = getSelectors();

const {
    selectBlockDefinitions,
    selectCreationMode,
    selectDraftPatterns,
    selectPatterns,
    selectSelectedPattern,
    selectModified,
    selectPatternHeader,
    selectCurrentlyEditingField,
    selectCurrentClickOnReferenceInfo,
    selectPushes,
    selectLoadingPushes,
    selectSelectedPush,
    selectDrawingToMostRecentPush,
    selectLoadingPush,
    selectTryingPattern,
    selectLoadingDetail,
    selectLoadingPatternList,
    selectPatternValidationResult,
    selectElementDefinitions,
    selectAssociatedLinks,
    selectLoadingAssociatedLinks,
    selectArchivedPatterns,
    selectLoadingArchivedList,
    selectErrorsOnValidation
} = patternsFeature;

const selectAutoVersion = createSelector(
    selectSelectedPattern,
    (pattern) => pattern?.autoVersion
);

const selectSelectedQueryParam = createSelector(selectQueryParams, (queryParams) => {
    return queryParams?.selected;
});

export interface PatternDraftCreationInfo {
    isDraft: boolean;
    duplicateCurrentPattern: boolean;
    selectedPush?: ID;
    selectedDrawing: any;
    autoVersion: boolean;
}

const selectDraftCreationInfoFromUrl = createSelector(
    selectQueryParams,
    fromRoot.selectCurrentFeature,
    (queryParams, currentFeature): PatternDraftCreationInfo | null => {
        if (currentFeature !== Route.patterns) return null;
        return {
            isDraft: !!queryParams?.new,
            duplicateCurrentPattern: !!queryParams?.duplicate,
            selectedPush: queryParams?.import,
            selectedDrawing: undefined,
            autoVersion: false
        };
    }
);

const selectSelectedAutoVersion = createSelector(
    selectSelectedPattern,
    (pattern): boolean => pattern?.autoVersion ?? false
);

const selectPatternDetailHeader = createSelector(
    selectSelectedPattern,
    (pattern: PatternDetail | null): HeaderItem | null => {
        if (!pattern) return null;
        return { name: pattern.title, description: pattern.description };
    }
);

const selectHeader = createSelector(
    selectPatternDetailHeader,
    selectPatternHeader,
    (detailHeader, editedHeader): HeaderItem | null => {
        if (!detailHeader) return null;
        return editedHeader ?? detailHeader;
    }
);

const selectCurrentlyEditingFieldForNode = (nodeId: ID) =>
    createSelector(
        selectCurrentlyEditingField,
        (current): { id: InlineEditorId } | null => {
            if (current && nodeId === current.nodeInfo.id) {
                return { id: current.id };
            }
            return null;
        }
    );

const selectIsInEditionMode = createSelector(
    selectCurrentlyEditingField,
    (current) => !!current?.id
);

export type GlobalEditorInfo = {
    title: string | null;
    isInEditionMode: boolean;
    initialValue: string;
    id: InlineEditorId;
} | null;

const selectGlobalEditorInfo = createSelector(
    selectCurrentlyEditingField,
    selectIsInEditionMode,
    (current, isInEditionMode) => {
        return {
            title: current ? `${current.nodeDisplayName}.${current.id.fieldName}` : null,
            isInEditionMode,
            initialValue: current?.initialValue ?? '',
            id: current?.id
        } as GlobalEditorInfo;
    }
);

const selectSourceId = createSelector(selectSelectedPattern, (pattern) =>
    pattern?.selectedPush ? pattern.selectedPush : pattern?.selectedDrawing
);

const selectExternalObjectLabels = createSelector(
    selectSelectedPush,
    (push) => push?.externalObjectLabels ?? []
);

/**
 * Information used to set the current source target icon and name
 *
 * Uses the selected push target framework instead of the pattern target framework.
 *
 */
const selectPushTargetFrameworkInfo = createSelector(
    selectSelectedPush,
    (selectedPush) => {
        if (!selectedPush) return TARGET_SOFTWARE_ICONS[DrawingTargetFramework.Unknown];
        return TARGET_SOFTWARE_ICONS[selectedPush.targetFramework];
    }
);

/**
 * This selector will always return the selected push.
 *
 * If autoVersion is selected it will select the latest push for the
 * selected drawing id.
 */
const selectPushId = createSelector(
    selectSourceId,
    selectDrawingToMostRecentPush,
    selectAutoVersion,
    (importParam, linkMap, autoVersion): ID | null => {
        if (autoVersion) {
            if (!linkMap || Object.keys(linkMap).length == 0 || !importParam) return null;
            return linkMap[importParam]?.id ?? null;
        }
        return !importParam ? null : importParam; // id can be empty string
    }
);

const selectPatternAndImport = createSelector(
    selectPushId,
    selectSelectedPattern,
    (importParam, pattern): { import: ID; pattern: PatternDetail } | null => {
        if (!pattern || !importParam) return null;

        return {
            import: importParam,
            pattern
        };
    }
);

const selectIsPushSelected = createSelector(
    selectPushId,
    selectLoadingPush,
    (pushId: ID | null, loadingPush: boolean) => !!pushId && !loadingPush
);

const selectIsLoadingView = createSelector(
    selectLoadingDetail,
    selectLoadingPatternList,
    (loadingDetail, loadingList) => loadingDetail || loadingList
);

export type PatternComponentView = {
    creationMode: boolean;
    selectedPattern: PatternDetail | null;
    tryingPattern: boolean;
    loading: boolean;
};

const selectPatternComponentView = createSelector(
    selectCreationMode,
    selectSelectedPattern,
    selectTryingPattern,
    selectIsLoadingView,
    (creationMode, selectedPattern, tryingPattern, loading): PatternComponentView => ({
        creationMode,
        selectedPattern,
        tryingPattern,
        loading
    })
);

const selectIsArchivedQueryParam = createSelector(selectQueryParams, (queryParams) => {
    return queryParams?.archived;
});

const selectIsReadonlyMode = createSelector(
    fromRoot.selectUserPermissions,
    selectIsArchivedQueryParam,
    (userPermission, isArchived) => !userPermission.isAbleToEditPatterns || isArchived
);

export type PatternToolbarView = {
    readonlyMode: boolean;
    patternDetailHeader: HeaderItem | null;
    creationMode: boolean;
    modified: boolean;
};

const selectPatternToolbarView = createSelector(
    selectIsReadonlyMode,
    selectPatternDetailHeader,
    selectCreationMode,
    selectModified,
    (readonlyMode, patternDetailHeader, creationMode, modified): PatternToolbarView => ({
        readonlyMode,
        patternDetailHeader,
        creationMode,
        modified
    })
);

const selectPatternExpressionErrors = createSelector(
    selectSelectedPattern,
    (pattern: PatternDetail | null): Errors | null =>
        pattern?.workRoadsTree
            ? treeExpressionErrors(pattern?.workRoadsTree)
            : { errors: [], warnings: [] }
);

export type GlobalErrors = {
    fieldErrors?: Errors;
    expressionErrors?: Errors;
};
const selectGlobalEditorValidationResult = createSelector(
    selectPatternValidationResult,
    selectGlobalEditorInfo,
    selectSelectedPattern,
    (validationResult, globalEditorInfo, pattern: PatternDetail | null): GlobalErrors => {
        if (!globalEditorInfo?.id)
            return { fieldErrors: undefined, expressionErrors: undefined };

        const nodeId = globalEditorInfo.id.nodeId;
        const fieldExpressionId = globalEditorInfo.id.fieldExpressionId;
        const fieldDefinitionId = globalEditorInfo.id.fieldDefinitionId;

        // errors saved on fields
        const expressionErrors = pattern
            ? findFieldInPattern(
                  {
                      fieldDefinitionId: fieldDefinitionId,
                      nodeId: nodeId
                  },
                  pattern.workRoadsTree?.childrenDefinitions ?? []
              )
            : undefined;

        const fieldErrors =
            validationResult?.nodeErrors[nodeId]?.fieldErrors?.[fieldDefinitionId];
        const expressionErrorsForField = {
            errors:
                expressionErrors?.errors.map(
                    (error) =>
                        ({
                            errorMessage: error,
                            errorType: ErrorType.EXPRESSION_ERROR,
                            fieldId: fieldDefinitionId,
                            nodeId
                        } as PatternValidationError)
                ) ?? [],
            warnings:
                expressionErrors?.warnings.map(
                    (warning) =>
                        ({
                            errorMessage: warning,
                            errorType: ErrorType.EXPRESSION_ERROR,
                            fieldId: fieldDefinitionId,
                            nodeId
                        } as PatternValidationError)
                ) ?? []
        };

        return {
            fieldErrors,
            expressionErrors: expressionErrorsForField
        };
    }
);

export type LinkSummaryWithUser = LinkSummary & { userName: string };
const selectAssociatedLinksAndItsUsers = createSelector(
    selectAssociatedLinks,
    selectLoadingAssociatedLinks,
    fromRoot.selectOrganizationUsersDisplayNames,
    (associatedLinks, loadingAssociatedLinks, userInfo): LinkSummaryWithUser[] | null =>
        loadingAssociatedLinks
            ? null
            : associatedLinks.map((link: LinkSummary) => ({
                  ...link,
                  userName: userInfo ? userInfo[link.exportedBy] : ''
              }))
);

const selectCanRestorePattern = createSelector(
    fromRoot.selectUserPermissions,
    (permissions) => permissions.isAbleToEditPatterns
);

export type GoToOption = {
    value: ID;
    label: string;
};

const selectAutocadNodes = createSelector(
    selectSelectedPattern,
    (pattern): AutocadTreeNode[] => {
        return pattern?.autocadTree
            ? [...flattenAutocadTree(pattern.autocadTree.childrenDefinitions)]
            : [];
    }
);

const selectWorkroadsNodes = createSelector(
    selectSelectedPattern,
    (pattern): WorkroadsTreeNode[] => {
        return pattern?.workRoadsTree
            ? [...flattenWorkRoadsTree(pattern.workRoadsTree.childrenDefinitions)]
            : [];
    }
);

const selectNodeMap = createSelector(
    selectAutocadNodes,
    selectWorkroadsNodes,
    (
        autocadNodes,
        workroadsNodes
    ): { [nodeId: ID]: WorkroadsTreeNode | AutocadTreeNode } => {
        const nodeMap: { [nodeId: ID]: WorkroadsTreeNode | AutocadTreeNode } = {};
        autocadNodes.forEach((node) => {
            nodeMap[(node.id ?? node.tId) as ID] = node;
        });
        workroadsNodes.forEach((node) => {
            nodeMap[(node.id ?? node.tId) as ID] = node;
        });
        return nodeMap;
    }
);

const autocadGoToOptions = createSelector(
    selectAutocadNodes,
    (autocadNodes): GoToOption[] => {
        const elementTypePipe = new ElementTypePipe();
        return autocadNodes
            .filter((item) => !item.isGoToNode)
            .map((item) => ({
                value: item.id ?? (item.tId as ID),
                label: `${elementTypePipe.transform(item.elementType)} (${
                    item.comment ?? ''
                })`
            }));
    }
);

const workroadsGoToOptions = createSelector(
    selectWorkroadsNodes,
    (workroadsNodes): GoToOption[] => {
        return workroadsNodes
            .filter((item) => !item.isGoToNode)
            .map((item) => ({
                value: item.id ?? (item.tId as ID),
                label: `${item.elementDefinition.name} (${item.comment ?? ''})`
            }));
    }
);

const selectTopErrorNotification = createSelector(
    selectPatternValidationResult,
    selectErrorsOnValidation,
    (validationResult, errors): string[] => {
        const result = new Set<string>();

        if (validationResult?.patternErrors.errors) {
            validationResult.patternErrors.errors.forEach((error) =>
                result.add(error.errorMessage)
            );
        }

        errors.forEach((error) => result.add(error));

        return [...result];
    }
);

const selectAllPatternErrors = createSelector(
    selectPatternValidationResult,
    selectErrorsOnValidation,
    selectPatternExpressionErrors,
    (validationResult, validationErrors, expressionErrors): PatternValidationError[] => {
        const totalErrors: PatternValidationError[] = [];
        const totalWarnings: PatternValidationError[] = [];
        if (validationResult) {
            const { errors, warnings } = flattenValidationStateErrors(
                validationResult,
                false
            );
            totalErrors.push(...errors);
            totalWarnings.push(...warnings);
        }

        totalErrors.push(
            ...validationErrors.map((error) => ({
                errorMessage: error,
                errorType: ErrorType.UNKNOWN,
                fieldId: '',
                nodeId: ''
            }))
        );

        totalErrors.push(...(expressionErrors?.errors ?? []));
        totalWarnings.push(...(expressionErrors?.warnings ?? []));
        return totalErrors;
    }
);

const selectNodesWithErrors = createSelector(
    selectAllPatternErrors,
    (allErrors): Set<ID> => {
        const nodesWithErrors = new Set<ID>();
        allErrors.forEach((error) => {
            if (error.nodeId) {
                nodesWithErrors.add(error.nodeId);
            }
        });
        return nodesWithErrors;
    }
);

export const fromPatterns = {
    selectPatternAndImport,
    selectIsPushSelected,
    selectBlockDefinitions,
    selectCreationMode,
    selectDraftCreationInfoFromUrl,
    selectDraftPatterns,
    selectSourceId,
    selectPatterns,
    selectSelectedPattern,
    selectSelectedQueryParam,
    selectPatternDetailHeader,
    selectModified,
    selectSelectedAutoVersion,
    selectPatternHeader,
    selectCurrentlyEditingFieldForNode,
    selectCurrentlyEditingField,
    selectIsInEditionMode,
    selectCurrentClickOnReferenceInfo,
    selectGlobalEditorInfo,
    selectLoadingDetail,
    selectSelectedPush,
    selectPushes,
    selectExternalObjectLabels,
    selectPushTargetFrameworkInfo,
    selectDrawingToMostRecentPush,
    selectLoadingPushes,
    selectPushId,
    selectLoadingPush,
    selectAutoVersion,
    selectTryingPattern,
    selectPatternComponentView,
    selectPatternToolbarView,
    selectHeader,
    selectPatternValidationResult,
    selectGlobalEditorValidationResult,
    selectElementDefinitions,
    selectAssociatedLinks,
    selectAssociatedLinksAndItsUsers,
    selectArchivedPatterns,
    selectLoadingArchivedList,
    selectLoadingAssociatedLinks,
    selectIsArchivedQueryParam,
    selectIsReadonlyMode,
    selectCanRestorePattern,
    workroadsGoToOptions,
    autocadGoToOptions,
    selectNodeMap,
    selectErrorsOnValidation,
    selectPatternExpressionErrors,
    selectTopErrorNotification,
    selectAllPatternErrors,
    selectNodesWithErrors
};
