import { Component, Input, SimpleChanges } from '@angular/core';
import {
    Errors,
    PatternValidationError,
    PatternValidationState
} from '../../../core/interfaces/general-validation-result.interface';
import { flattenValidationStateErrors } from 'src/app/core/helpers/pattern-utils';

@Component({
    selector: 'app-cycle-errors',
    templateUrl: './cycle-errors.component.html',
    styleUrls: ['./cycle-errors.component.scss']
})
export class CycleErrorsComponent {
    @Input() cycleErrors?: Errors | null;
    @Input() validationState?: PatternValidationState | null;
    @Input() useExpand = true;
    @Input() toolbarMode = false;

    errorCountLabel: string | null = null;
    warningCountLabel: string | null = null;
    totalErrors: PatternValidationError[] = [];
    totalWarnings: PatternValidationError[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cycleErrors || changes.validationState) {
            this.calculate();
        }
    }

    private calculate(): void {
        const totalErrors: PatternValidationError[] = [];
        const totalWarnings: PatternValidationError[] = [];
        if (this.validationState) {
            const { errors, warnings } = flattenValidationStateErrors(
                this.validationState,
                true
            );
            totalErrors.push(...errors);
            totalWarnings.push(...warnings);
        }
        if (this.cycleErrors) {
            totalErrors.push(...this.cycleErrors.errors);
            totalWarnings.push(...this.cycleErrors.warnings);
        }

        this.errorCountLabel = this.calculateErrorCountLabel(totalErrors);
        this.warningCountLabel = this.calculateWarningCountLabel(totalWarnings);
        this.totalErrors = totalErrors;
        this.totalWarnings = totalWarnings;
    }

    private calculateErrorCountLabel(errors: PatternValidationError[]): string | null {
        if (errors.length == 0) {
            return null;
        }
        if (errors.length == 1) {
            return '1 error';
        }
        return `${errors.length} errors`;
    }

    private calculateWarningCountLabel(
        warnings: PatternValidationError[]
    ): string | null {
        if (warnings.length == 0) {
            return null;
        }
        if (warnings.length == 1) {
            return '1 warning';
        }
        return `${warnings.length} warnings`;
    }
}
