import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

export interface Option {
    value: any;
    label: string;
}

export interface OptionGroup {
    label: string;
    options: Option[];
}

const SELECT_CONTROL_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectComponent),
    multi: true
};

@Component({
    selector: 'app-custom-select',
    providers: [SELECT_CONTROL_ACCESSOR],
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements ControlValueAccessor {
    @Input() options!: Option[] | null;
    @Input() groups?: OptionGroup[];
    @Input() label!: string;
    @Input() value: any = null;
    @Input() placeholder = '';
    @Output() valueChange = new EventEmitter<any>();
    @Output() focusOutField = new EventEmitter();
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() multiple = false;
    @Input() valueNotInOptionsIsValid = false;
    // This is used to provide a label when value is not in options
    @Input() currentLabel?: string | null;
    @Input() disabled = false;
    @Input() labelTemplate?: TemplateRef<any>;
    @Input() showAddOnMultiple = false;

    @ViewChild('menu') menu!: MatSelect;

    private onChange!: any;
    private onTouched!: any;
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onValueChange(val: any) {
        this.value = val;
        this.onChange?.(val);
        this.onTouched?.();
        this.valueChange.emit(val);
    }

    focusOut() {
        this.focusOutField.emit();
    }
    get valueInOptions() {
        if (this.value == null || (!this.options && !this.groups)) {
            return true;
        }
        const options =
            this.options ??
            this.groups!.reduce((previous, current) => {
                return [...previous, ...current.options];
            }, [] as Option[]);
        return options.some((o) => o.value === this.value);
    }

    close() {
        this.menu.close();
    }
}
