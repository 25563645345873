import { ID } from '../definitions/types';

export interface GeneralValidationResult {
    errors: PatternValidationError[];
}

export interface PatternValidationError {
    nodeId: ID;
    fieldId: ID;
    errorType: ErrorType;
    errorMessage: string;
}

export interface Errors {
    errors: PatternValidationError[];
    warnings: PatternValidationError[];
}

export enum ErrorType {
    UNKNOWN,
    MISSING_IDS,
    CIRCULAR_REFERENCE,
    INFINITE_LOOP,
    MISSING_CORRESPONDING_GOTO,
    LINK_NOT_FOUND,
    TARGET_NOT_FOUND,
    ENTITY_NOT_FOUND,
    EXPRESSION_ERROR
}

export type PatternValidationState = {
    nodeErrors: { [nodeId: ID]: NodeErrors };
    patternErrors: Errors;
    validationErrors: PatternValidationError[];
};

export type FieldErrors = { [fieldId: ID]: Errors };

export type NodeErrors = {
    fieldErrors: FieldErrors;
    nodeErrors: Errors;
};

export function adaptToState(result: GeneralValidationResult): PatternValidationState {
    const state: PatternValidationState = {
        nodeErrors: {},
        patternErrors: {
            errors: [],
            warnings: []
        },
        validationErrors: []
    };

    function initializeFieldErrors(nodeId: ID, fieldId: ID) {
        state.nodeErrors[nodeId].fieldErrors[fieldId] = {
            errors: [],
            warnings: []
        };
    }

    function initializeNodeErrors(nodeId: ID) {
        state.nodeErrors[nodeId] = {
            nodeErrors: {
                errors: [],
                warnings: []
            } as Errors,
            fieldErrors: {} as FieldErrors
        } as NodeErrors;
    }
    result.errors.forEach((error) => {
        const { nodeId, fieldId, errorMessage, errorType } = error;
        if (!nodeId) {
            state.patternErrors.errors.push(error);
        } else {
            if (!state.nodeErrors[nodeId]) {
                initializeNodeErrors(nodeId);
            }
            if (!fieldId) {
                state.nodeErrors[nodeId].nodeErrors.errors.push(error);
            } else {
                if (!state.nodeErrors[nodeId].fieldErrors[fieldId]) {
                    initializeFieldErrors(nodeId, fieldId);
                }
                state.nodeErrors[nodeId].fieldErrors[fieldId].errors.push(error);
            }
        }
    });
    return state;
}
