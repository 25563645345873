import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    fromPatterns,
    LinkSummaryWithUser,
    PatternToolbarView
} from 'src/app/modules/patterns/store/patterns.selectors';
import { Observable, Subject, of } from 'rxjs';
import { Action } from 'src/app/shared/interfaces/action';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import * as patternsActions from '../../store/actions/patterns.action';
import { PatternToolbarFormComponent } from '../pattern-toolbar-form/pattern-toolbar-form.component';
import { DraftRoutingService } from '../../../layout/services/draft-routing.service';
import { MatDialog } from '@angular/material/dialog';
import { DeletePatternConfirmationComponent } from '../delete-pattern-confirmation/delete-pattern-confirmation.component';
import { ID } from 'src/app/core/definitions/types';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
import {
    Errors,
    PatternValidationError,
    PatternValidationState
} from 'src/app/core/interfaces/general-validation-result.interface';

@Component({
    selector: 'app-toolbar-patterns',
    templateUrl: './toolbar-patterns.component.html',
    styleUrls: ['./toolbar-patterns.component.scss']
})
export class ToolbarPatternsComponent implements OnInit, OnDestroy {
    view$: Observable<PatternToolbarView>;
    isPushSelected$: Observable<boolean>;

    tryingPattern$: Observable<boolean>;

    isArchived$: Observable<boolean>;
    onDestroy$ = new Subject<boolean>();

    tryingPattern = false;
    patternHeader: { id: ID; title: string } | null = null;

    @ViewChild(PatternToolbarFormComponent) form?: PatternToolbarFormComponent;

    MENU_ACTIONS = [
        {
            icon: 'file_copy',
            iconFirst: true,
            emitEvent: () => this.duplicatePattern(),
            label: 'Duplicate Pattern',
            disabled: this.tryingPattern
        },
        {
            icon: 'delete',
            iconFirst: true,
            emitEvent: () => this.openDeleteDialog(),
            label: 'Archive Pattern',
            style: { color: 'var(--remove)' }
        }
    ] as Action[];

    canRestore$: Observable<boolean> = this.store.select(
        fromPatterns.selectCanRestorePattern
    );
    patternValidationResult$: Observable<PatternValidationState | null>;
    allPatternErrors$: Observable<PatternValidationError[]>;

    constructor(
        private store: Store,
        private draftRoutingService: DraftRoutingService,
        private dialog: MatDialog
    ) {
        this.tryingPattern$ = this.store.select(fromPatterns.selectTryingPattern);
        this.isPushSelected$ = this.store.select(fromPatterns.selectIsPushSelected);
        this.view$ = this.store.select(fromPatterns.selectPatternToolbarView);
        this.isArchived$ = this.store.select(fromPatterns.selectIsArchivedQueryParam);
        this.patternValidationResult$ = this.store.select(
            fromPatterns.selectPatternValidationResult
        );
        this.allPatternErrors$ = this.store.select(fromPatterns.selectAllPatternErrors);
    }

    ngOnInit(): void {
        this.tryingPattern$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((t) => (this.MENU_ACTIONS[0].disabled = t));
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.unsubscribe();
    }

    onSubmit(): void {
        this.store.dispatch(patternsActions.savePattern());
    }

    onDiscard(): void {
        this.store.dispatch(patternsActions.patternDetailDiscard({ discard: true }));
    }

    duplicatePattern() {
        this.draftRoutingService.createDuplicatePatternDraft();
    }

    onTryPattern(tryingPattern: boolean): void {
        this.store.dispatch(patternsActions.setTryingPattern({ tryingPattern }));
    }

    onRestorePattern(): void {
        this.store.dispatch(patternsActions.restorePattern());
    }

    clickOutside() {
        this.form?.onOutsideClick();
    }

    onDelete(selectedLinks: ID[]): void {
        this.store.dispatch(
            patternsActions.deletePatternOrDraft({ linksToDelete: selectedLinks })
        );
    }

    openDeleteDialog(): void {
        this.dialog.open(DeletePatternConfirmationComponent, {
            width: '600px',
            autoFocus: false,
            height: '500px',
            data: {
                onAccept: (selectedLinks: ID[]) => this.onDelete(selectedLinks)
            }
        });
    }

    get isFormValid(): boolean {
        return !!this.form?.valid;
    }

    get errors(): Errors {
        return {
            errors: [],
            warnings: []
        };
    }
}
