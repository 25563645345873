import { FilterTypeGroup } from '../../../../shared/components/flow-filter-builder/flow-filter-builder.component';
import { CODE_NAME_COLUMN_NAME } from '../../constants/columns';

export const CUSTOM_LABEL = 'Custom';
export const CUSTOM_FUNCTION = 'CUSTOM_FUNCTION';
export const CODE_NAME = 'CODE_NAME';
export const QUANTITY = 'QUANTITY';
export const QUANTITY_FIELD_ID = 'QUANTITY';
export const ENABLE_IF_FIELD_ID = 'ENABLE_IF';

export const labels: { [key: string]: string } = {
    CODE_NAME: 'Code Name',
    CUSTOM_FUNCTION: 'Custom Function (FLOW)',
    QUANTITY: 'Quantity'
};

export const groups: FilterTypeGroup[] = [
    {
        label: CODE_NAME_COLUMN_NAME,
        options: [CODE_NAME]
    },
    {
        label: 'Custom',
        options: [CUSTOM_FUNCTION]
    }
];

export const NULL_LABEL = 'EMPTY';
