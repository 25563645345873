import { Node } from '../../modules/linked-trees/interfaces/node';
import { AutocadTreeNode } from '../interfaces/autocad-tree-node.interface';
import { WorkroadsTreeNode } from '../interfaces/workroads-tree-node.interface';
import { FieldExpressionDefinition } from '../interfaces/field-expression-definition.interface';
import { ID } from '../definitions/types';
import { WorkroadsNodeContent } from '../interfaces/workroads-node-content.interface';
import { WorkroadsTree } from '../interfaces/workroads-tree.interface';
import {
    Errors,
    ErrorType,
    PatternValidationError,
    PatternValidationState
} from '../interfaces/general-validation-result.interface';
import {
    ENABLE_IF_FIELD_ID,
    QUANTITY,
    QUANTITY_FIELD_ID
} from 'src/app/modules/list-tree-view/components/report-filters/constants';

export function* flattenTree(nodes: Node[]): Iterable<Node> {
    for (const node of nodes) {
        yield node;
        if (node.children) yield* flattenTree(node.children);
    }
}

export function* flattenAutocadTree(nodes: AutocadTreeNode[]): Iterable<AutocadTreeNode> {
    for (const node of nodes) {
        yield node;
        if (node.childrenDefinitions) yield* flattenAutocadTree(node.childrenDefinitions);
    }
}

export function* flattenWorkRoadsTree(
    nodes: WorkroadsTreeNode[]
): Iterable<WorkroadsTreeNode> {
    for (const node of nodes) {
        yield node;
        if (node.childrenDefinitions)
            yield* flattenWorkRoadsTree(node.childrenDefinitions);
    }
}

export function nodeExpressionErrors(node: WorkroadsNodeContent, nodeId: ID): Errors {
    const errors: PatternValidationError[] = [];
    const warnings: PatternValidationError[] = [];

    node.quantityErrors?.forEach((item) => {
        errors.push({
            errorMessage: item,
            errorType: ErrorType.EXPRESSION_ERROR,
            fieldId: QUANTITY_FIELD_ID,
            nodeId
        });
    });

    node.quantityWarnings?.forEach((item) => {
        warnings.push({
            errorMessage: item,
            errorType: ErrorType.EXPRESSION_ERROR,
            fieldId: ENABLE_IF_FIELD_ID,
            nodeId
        });
    });

    node.enabledIfErrors?.forEach((item) => {
        errors.push({
            errorMessage: item,
            errorType: ErrorType.EXPRESSION_ERROR,
            fieldId: ENABLE_IF_FIELD_ID,
            nodeId
        });
    });

    node.enabledIfWarnings?.forEach((item) => {
        errors.push({
            errorMessage: item,
            errorType: ErrorType.EXPRESSION_ERROR,
            fieldId: ENABLE_IF_FIELD_ID,
            nodeId
        });
    });

    node.fieldExpressions?.forEach((item) => {
        item.errors?.forEach((error) => {
            errors.push({
                errorMessage: error,
                errorType: ErrorType.EXPRESSION_ERROR,
                fieldId: item.fieldDefinitionId,
                nodeId
            });
        });

        item.warnings?.forEach((warning) => {
            warnings.push({
                errorMessage: warning,
                errorType: ErrorType.EXPRESSION_ERROR,
                fieldId: item.fieldDefinitionId,
                nodeId
            });
        });
    });

    return { errors, warnings };
}

export function treeExpressionErrors(tree: WorkroadsTree): Errors {
    const nodes = [...flattenWorkRoadsTree(tree.childrenDefinitions)];
    const totalErrors: PatternValidationError[] = [];
    const totalWarnings: PatternValidationError[] = [];
    nodes.forEach((node) => {
        if (!node.isGoToNode) {
            const { errors, warnings } = nodeExpressionErrors(
                node,
                node.id ?? node.tId ?? ''
            );
            totalErrors.push(...errors);
            totalWarnings.push(...warnings);
        }
    });
    return { errors: totalErrors, warnings: totalWarnings };
}

export function nodeHasErrors(node: WorkroadsNodeContent): boolean {
    const nodeHasQuantityErrors = !!node.quantityErrors?.length;
    const itemHasErrors = node.fieldExpressions?.some((fieldExpression) => {
        return !!fieldExpression.errors?.length;
    });
    return nodeHasQuantityErrors || itemHasErrors;
}

export function treeHasErrors(tree: WorkroadsTree): boolean {
    const nodes = [...flattenWorkRoadsTree(tree.childrenDefinitions)];
    return nodes.some((node) => {
        if (node.isGoToNode) {
            return false;
        }

        return nodeHasErrors(node);
    });
}

export function findFieldInPattern(
    id: { nodeId: ID; fieldDefinitionId: ID },
    nodes: WorkroadsTreeNode[]
): FieldExpressionDefinition | null {
    for (const node of nodes) {
        if (node.id === id.nodeId) {
            if (id.fieldDefinitionId === QUANTITY_FIELD_ID) {
                return {
                    errors: node.quantityErrors ?? [],
                    warnings: node.quantityWarnings ?? [],
                    expression: node.quantityExpression ?? '',
                    fieldDefinitionId: QUANTITY,
                    id: QUANTITY
                } as any;
            }

            if (id.fieldDefinitionId === ENABLE_IF_FIELD_ID) {
                return {
                    errors: node.enabledIfErrors ?? [],
                    warnings: node.enabledIfWarnings ?? [],
                    expression: node.enabledIfExpression ?? '',
                    fieldDefinitionId: QUANTITY,
                    id: QUANTITY
                } as any;
            }

            const field = node.fieldExpressions.find(
                (item) => item.fieldDefinitionId === id.fieldDefinitionId
            );
            if (field) {
                return field;
            }
        }
        if (node.childrenDefinitions) {
            const field = findFieldInPattern(id, node.childrenDefinitions);
            if (field) {
                return field;
            }
        }
    }
    return null;
}

export function flattenValidationStateErrors(
    validationState: PatternValidationState,
    showOnlyPatterns = false
) {
    const errors: PatternValidationError[] = [];
    const warnings: PatternValidationError[] = [];

    errors.push(...(validationState?.patternErrors.errors ?? []));
    warnings.push(...(validationState?.patternErrors.warnings ?? []));

    errors.push(...(validationState?.validationErrors ?? []));

    if (showOnlyPatterns) {
        return { errors, warnings };
    }
    Object.values(validationState?.nodeErrors ?? {}).forEach((item) => {
        errors.push(...(item.nodeErrors.errors ?? []));
        warnings.push(...(item.nodeErrors.warnings ?? []));

        Object.values(item.fieldErrors ?? {}).forEach((field) => {
            errors.push(...(field.errors ?? []));
            warnings.push(...(field.warnings ?? []));
        });
    });

    return { errors, warnings };
}
