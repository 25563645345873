import { Pipe, PipeTransform } from '@angular/core';
import {
    ErrorType,
    PatternValidationError
} from 'src/app/core/interfaces/general-validation-result.interface';

@Pipe({
    name: 'errorMessage'
})
export class ErrorMessagePipe implements PipeTransform {
    errorMessage: { [key in ErrorType]: string | undefined } = {
        [ErrorType.LINK_NOT_FOUND]: 'Link not found for node',
        [ErrorType.MISSING_IDS]: 'Missing ids',
        [ErrorType.MISSING_CORRESPONDING_GOTO]:
            'Loop node has missing corresponding loop node',
        [ErrorType.TARGET_NOT_FOUND]: 'Loop node has no target',
        [ErrorType.INFINITE_LOOP]:
            'his Pattern might fall into an infinite loop when applied to this model',
        [ErrorType.ENTITY_NOT_FOUND]: 'Entity not found',
        [ErrorType.CIRCULAR_REFERENCE]: undefined,
        [ErrorType.UNKNOWN]: undefined,
        [ErrorType.EXPRESSION_ERROR]: undefined
    };

    transform(value: PatternValidationError, ...args: unknown[]): unknown {
        const staticMessage = this.errorMessage[value.errorType];

        if (staticMessage) {
            return staticMessage;
        }

        if (
            value.errorType === ErrorType.EXPRESSION_ERROR ||
            value.errorType === ErrorType.CIRCULAR_REFERENCE ||
            value.errorType === ErrorType.UNKNOWN
        ) {
            return value.errorMessage;
        }

        return 'Unknown error';
    }
}
